import { graphql, useStaticQuery } from "gatsby"

interface Site {
  site: {
    siteMetadata: {
      author: string
      title: string
      shortTitle: string
      impressum: {
        vorstand: string[]
        registergericht: string
        registernummer: string
        inhaltlichVerantwortlicher: string
        contact: {
          street: string
          plz: string
          town: string
          email: string
          phone: string
          fax: string
        }
      }
    }
  }
}

const query = graphql`
  query {
    site {
      siteMetadata {
        author
        title
        shortTitle
        impressum {
          vorstand
          registergericht
          registernummer
          inhaltlichVerantwortlicher
          contact {
            street
            plz
            town
            email
            phone
            fax
          }
        }
      }
    }
  }
`

const useSite = () => {
  return useStaticQuery<Site>(query).site
}

export const useSiteMetadata = () => {
  return useSite().siteMetadata
}
