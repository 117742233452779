import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#7e1901",
    },
    secondary: {
      main: "#f98606",
    },
  },
})

export default theme
