import React, { FC } from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  AppBar,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { InternalLink } from "./layout"

interface Props {
  navigation: InternalLink[]
}

const Header: FC<Props> = ({ navigation }) => {
  function renderLogo() {
    return <Typography style={{ flex: 1 }}>(Logo)</Typography>
  }

  function renderNavigationListItem(internalLink: InternalLink) {
    return (
      <ListItem button key={`navigation-${internalLink.to}`}>
        <Link
          component={GatsbyLink}
          color="inherit"
          underline="none"
          to={internalLink.to}
        >
          <ListItemText>{internalLink.text}</ListItemText>
        </Link>
      </ListItem>
    )
  }

  function renderNavigationList() {
    return (
      <List
        style={{
          padding: 0,
          display: "flex",
        }}
        component="nav"
      >
        {navigation.map(internalLink => renderNavigationListItem(internalLink))}
      </List>
    )
  }

  return (
    <AppBar position="static">
      <Container>
        <Toolbar>
          {renderLogo()}
          {renderNavigationList()}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
