import React, { FC } from "react"
import styled from "@emotion/styled"
import { Container } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core/styles"
import Header from "./header"
import Footer from "./footer"
import { useSiteMetadata } from "../hooks/site"
import theme from "../theme"

const StyledSection = styled.section`
  padding: 30px 0;
`

export interface InternalLink {
  to: string
  text: string
}

const Layout: FC = ({ children }) => {
  const { author } = useSiteMetadata()

  const headerNavigationLinks: InternalLink[] = [
    { to: "/", text: "Home" },
    { to: "/verein", text: "Verein" },
    { to: "/chronik", text: "Chronik" },
    { to: "/mitgliedschaft", text: "Mitgliedschaft" },
    { to: "/kontakt", text: "Kontakt" },
  ]

  const footerNavigationLinks: InternalLink[] = [
    { to: "/kontakt", text: "Kontakt" },
    { to: "/datenschutz", text: "Datenschutz" },
    { to: "/impressum", text: "Impressum" },
  ]

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header navigation={headerNavigationLinks} />
      <Container>
        <StyledSection>{children}</StyledSection>
      </Container>
      <Footer author={author} navigation={footerNavigationLinks} />
    </ThemeProvider>
  )
}

export default Layout
