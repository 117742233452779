import React, { FC } from "react"
import { InternalLink } from "./layout"
import {
  AppBar,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { Link as GatsbyLink } from "gatsby"

interface Props {
  author: string
  navigation: InternalLink[]
}

const Footer: FC<Props> = ({ author, navigation }) => {
  function renderCopyright() {
    const startYear = 2021
    const date = new Date()
    const currentYear = date.getFullYear()
    const prefix = startYear !== currentYear ? `${startYear}-` : ""

    return (
      <Typography style={{ flex: 1 }}>
        © {prefix}
        {currentYear}, {author}
      </Typography>
    )
  }

  function renderNavigation() {
    return (
      <List
        style={{
          padding: 0,
          display: "flex",
        }}
        component="nav"
      >
        {navigation.map(internalLink => (
          <ListItem button key={`navigation-${internalLink.to}`}>
            <Link
              component={GatsbyLink}
              color="inherit"
              underline="none"
              to={internalLink.to}
            >
              <ListItemText>{internalLink.text}</ListItemText>
            </Link>
          </ListItem>
        ))}
      </List>
    )
  }

  return (
    <AppBar component="footer" position="static">
      <Container>
        <Toolbar>
          {renderCopyright()}
          {renderNavigation()}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Footer
